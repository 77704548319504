:root {
  --hue: 103;
}

// $mainColor: #b2dca2;
$mainColor: hsl(var(--hue), 45%, 75%);
$red: #dc3545;
$white: #eee;

$_dark: #343a40;
$dark: darken($_dark, 11%);
$darker: darken($dark, 1%);

// $darker: red;

