@import "src/vars.scss";
@import "./cardStyles.scss";

.showcase {
  padding-top: 10px;

  .header {
    display: grid;
    justify-items: center;
    align-items: center;

    a {
      box-sizing: border-box;
    }

    .linkContainer a:hover {
      text-decoration: none;
      // border-top: 3px solid $dark;
      // border-bottom: 3px solid $mainColor;
      box-shadow: 0 1px 0 $mainColor;
      color: inherit;
    }

    @media (max-width: 1100px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-areas:
        "title title title"
        "btn   links links"
        "s     s     s";

      .linkContainer {
        text-align: center;
      }

      .tagSearch {
        grid-area: s !important;
      }
    }

    @media (min-width: 1100px) {
      grid-template-columns: 1fr 2fr 1fr;
      grid-template-areas: "btn title links"
                           "s s s";
    }

    background-color: $darker;

    margin: 10px 0 5px 0;
    padding: 5px;
    .btnContainer,
    .linkContainer {
      display: grid;
      align-items: center;

      grid-auto-flow: column;
      gap: 1em;
      width: 100%;
    }

    .btnContainer {
      grid-area: btn;

      a {
        border: none !important;
      }

      .backBtn {
        color: $red;
        font-size: 20px;
        text-align: center;
        // width: 50%;
        border-radius: 5px;
        padding-bottom: 2px;

        transition: 0.25s background-color, 0.25s color;
        &:hover {
          background-color: $red;
          color: $white;
        }
      }
    }

    .linkContainer {
      color: $mainColor;
      grid-area: links;
      font-size: 1.25em;
      text-align: center;
    }

    .tagSearch {

      &.empty {
        grid-area: links;

      }
      &:not(.empty) {
        grid-area: s;
      }
    }

    h1 {
      grid-area: title;
      color: $mainColor;
      text-align: center;
      margin: 0;
      font-size: 3em;
    }
  }

  .showcaseItem {
    overflow: hidden;

    margin-bottom: 20px;

    &.shown {
      display: grid;
    }

    &.hidden {
      display: none;
    }

    iframe {
      width: 100%;
      height: 600px;

      margin: 0 auto;
      border: none;
    }

    .scCarousel {
      .slide {
        background: $darker;
      }

      .carouselPage {
        &.mobile {
          $maxHeight: 100vw;
          max-height: $maxHeight;
          img,
          video {
            width: 100%;
          }
        }

        &.desktop {
          $maxHeight: 500px;
          max-height: $maxHeight;
          img,
          video {
            max-height: $maxHeight;
            width: auto;
          }
        }
      }
    }

    .markdown {
      & > p:first-of-type {
        text-align: center;
      }

      h1 {
        color: $mainColor;
        padding-bottom: 5px;
        border-bottom: 2px solid $mainColor;
      }

      h2 {
        font-size: 1em;
      }

      ul {
        padding-left: 20px;
      }
    }

    h2 {
      padding-top: 2.5em;
      text-align: center;
      color: $mainColor;
      border-bottom: 1px solid $mainColor;
    }
  }
}

.react-select {

  min-width: 200px;
  
  .react-select__placeholder{
    z-index: 200 !important;
  }

  * {
    color: $mainColor !important;
    background-color: $darker !important;

  }

  &__option--is-focused {
      background-color: $dark !important;
  }

  &__control {

    &--is-focused {
        // border: 1px solid $mainColor !important;
        // box-shadow: none !important;
        box-shadow: 0 0 0 1px $mainColor !important;
      }

    border-radius: 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid $mainColor !important;
  }


}