@import "src/vars.scss";

.topBar {
  color: $mainColor;
  background-color: $dark;
  z-index: 100;

  position: fixed;
  top: 0px;
  left: 0;
  width: 100vw;

  display: grid;
  grid-auto-flow: column;

  grid-template-columns: 1fr 1fr 1fr;

  .barLink {
    text-align: center;
    font-size: 1.2em;
    a {
      margin: 0 auto;
      transition: background-color 0.25s;
      white-space: nowrap;
      display: block;

      max-width: 100%;
      text-decoration: none;
      padding: 5px 0;

      &:hover {
        color: $mainColor;
        border-bottom: 2px solid $mainColor;
      }
    }
  }
}
