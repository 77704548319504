@import "src/vars.scss";
@import "./BS.scss";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&family=Roboto:ital@0;1&display=swap");

a {
  color: inherit;
  text-decoration: none;
}

html,
#root {
  background-color: $dark;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  overflow-y: scroll; // Prevent jitter from scroll bar popping in/out when page shrinks below window height
}

#App {
  padding: 0 5px;
}

::-webkit-scrollbar {
  width: 10px;

  &-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &-thumb {
    background: $mainColor;
  }
}

.text-white {
  color: $white;
}

._container {
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  ._container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  ._container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  ._container {
    width: 1170px;
  }
}

h1,
h2,
h3,
h4,
h5,
a,
.backBtn {
  font-family: "Roboto";
  text-transform: uppercase;
}