@import "src/vars.scss";

$gap: 10px;

$ratio: 1.5;
.cardGrid {
  display: grid;
  row-gap: 2em;
  column-gap: 1em;
  justify-items: center;
  cursor: pointer;

  .sCard {
    background-color: $darker;
    transition: box-shadow 0.25s, background-color 0.25s;

    padding-bottom: 50px;

    position: relative;
    * {
      position: relative;
      text-align: center;
    }

    .title {
      margin-top: -42px;
      background-color: rgba($darker, $alpha: 0.85);

      padding: 5px 0;
      text-align: center;
      transition: background-color 0.25s;
    }

    &.selected {
      opacity: 0.75;
    }

    &.selected,
    &:hover {
      background-color: $darker;
      box-shadow: 0 0 10px -6px $mainColor;

      .imgWrapper {
        filter: grayscale(0);
      }

      .title {
        // background-color: darken($darker, 1%);
        background-color: $darker;
      }
    }

    & > *:not(.imgWrapper) {
      padding-left: $gap;
      padding-right: $gap;
    }

    .imgWrapper {
      background-color: $mainColor;
      padding: 0;
      // transition: filter 0.25s;

      filter: grayscale(0.1);

      img {
        width: 100%;
      }
    }

    .title {
      color: $mainColor;
      padding-top: $gap;
    }

    .tagContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr 1fr;

      width: 100%;
      row-gap: $gap;

      padding: 10px 0;

      .tag {
        color: $white;
        font-size: 75%;
        font-style: italic;
        text-align: center;
      }
    }

    p {
      color: $white;
      // text-align: justify;
      height: 50px;
    }

    .links {
      position: absolute;
      bottom: 0;
      width: 100%;
      font-size: 1.5em;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $gap;

      padding: $gap;

      a {
        color: $mainColor;
        text-decoration: none;

        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, var(--opacity)) 0%,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, var(--opacity)) 100%
        );

        --opacity: 0.1;
        &:hover {
          --opacity: 0.2;
        }

        .icon {
          position: absolute;
          left: 5px;
        }

        .text {
          // padding-left: 5px;
          width: 100%;
          display: block;
        }
      }
    }
  }
}

@mixin cardGrid($columns) {
  .cardGrid {
    grid-template-columns: repeat($columns, 1fr);
  }
}

@media (min-width: 1200px) {
  @include cardGrid(3);
}

@media (min-width: 992px) and (max-width: 1200px) {
  @include cardGrid(3);
}

@media (min-width: 650px) and (max-width: 992px) {
  @include cardGrid(2);
}

@media (max-width: 650px) {
  @include cardGrid(1);

  // Stack github and project links on small screens
  .cardGrid {
    gap: 3em;
    .links {
      // display: block !important;
      grid-template-columns: auto !important;
      row-gap: 10px;
    }

    a {
      margin: $gap 0 0 0;
    }

    .sCard {
      padding-bottom: 110px !important;
      margin-top: 25px;
    }
  }
}
